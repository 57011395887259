import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { MagnifyingGlassIcon, ServerIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

import { getDevices, selectDevices } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import Pagination from '../components/Pagination';
import EmptyState from '../components/EmptyState';

export const Devices = () => {
  const { filter, pageNumber } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const page = parseInt(pageNumber) || 1;
  const getSelectedDevices = useMemo(selectDevices, []);
  const pageData = useSelector(state => getSelectedDevices(state, { filter, page }));

  useEffect(() => {
    dispatch(getDevices());
  }, []); // eslint-disable-line

  const filterHandler = e => {
    const filter = encodeURIComponent(e.target.value);
    history.push(`/stations/${page}/${filter}`);
  };

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <Link to="/stations/add" className="mr-8 flex-1 flex items-center ">
        <PlusCircleIcon className="mr-2 h-5 w-5 hover:text-indigo-900 text-indigo-400" aria-hidden="true" />
        <span className="text-gray-900 hover:underline">Add Station</span>
      </Link>

      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          className="focus:ring-indigo-700 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
          defaultValue={filter}
          onChange={filterHandler}
        />
      </div>
    </div>
  );

  return (
    <PrivateTemplate headerTitle="Stations" headerActions={headerActions}>
      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          {pageData.items.length === 0 ? (
            <EmptyState
              title="No Stations"
              description="Get started by adding a new station."
              cta="Add Station"
              to="/stations/add"
            />
          ) : (
            <div className="overflow-scroll border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Station
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Active Job
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageData.items.map(device => (
                    <tr key={device.deviceId}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        {device.activeJobId ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Running
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Paused
                          </span>
                        )}
                      </td>

                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <Link to={`/station/${device.deviceId}/settings`}>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8">
                              {device.activeJobId ? (
                                <ServerIcon className="h-6 w-6 text-gray-400" />
                              ) : (
                                <ServerIcon className="h-6 w-6 text-gray-200" />
                              )}
                            </div>
                            <div className="ml-2 lg:ml-6">
                              <div className="text-sm font-medium ">{device.deviceName}</div>
                              <div className="text-sm text-gray-500">{device.deviceId}</div>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        {device.activeJobId && (
                          <Link to={`/station/${device.deviceId}/jobs`}>
                            <div className="text-sm">{device.activeJobName}</div>
                            <div className="text-sm text-gray-500">{device.activeJobId}</div>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pageData={pageData} baseUrl={`/stations/${filter}`} />
            </div>
          )}
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Devices;
