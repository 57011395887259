import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ChartBarSquareIcon, FaceSmileIcon, FaceFrownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import { getProducts, selectProducts } from 'state/slices/productsSlice';

import useInterval from 'utils/useInterval';

import Pagination from '../components/Pagination';
import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import Tabs from '../components/Tabs';

import getProductNav from '../navs/productNav';

export const Products = props => {
  const dispatch = useDispatch();
  const { jobId, paramA: pageSlug, paramB: qualitySlug } = useParams();

  const { sectionNav, headerTitle, sectionTitle } = props;
  const page = parseInt(pageSlug, 10) || 1;
  const quality = qualitySlug || 'recent';

  const getSelectedProducts = useMemo(selectProducts, []);
  const pageData = useSelector(state => getSelectedProducts(state, { jobId, filter: quality, page }));
  const next = useSelector(state => state.products.next[jobId]);

  const query = {
    sort: 'reverse',
  };

  if (qualitySlug) {
    query.quality = quality.toUpperCase();
  }

  useEffect(() => {
    dispatch(getProducts({ jobId, query }));
  }, [jobId, quality]); // eslint-disable-line

  useInterval(() => {
    if (next && pageData.total < 500) query.next = next;
    dispatch(getProducts({ jobId, query }));
  }, 10 * 1000);

  const products = pageData.items.map(product => {
    const [image, ...images] = Object.values(product.images); // eslint-disable-line

    let icon;
    switch (product.predictedQuality) {
      case 'GOOD':
        icon = <FaceSmileIcon className="text-green-500 h-6 w-6" />;
        break;
      case 'BAD':
        icon = <FaceFrownIcon className="text-red-500 h-6 w-6" />;
        break;
      default:
        icon = <QuestionMarkCircleIcon className="text-yellow-500 h-6 w-6" />;
    }
    return (
      <li key={product.productId} className="relative">
        <Link
          className="group block w-full rounded-md border border-gray-50 hover:ring-1 hover:ring-offset-2 hover:ring-offset-gray-100 hover:ring-gray-500"
          to={`/job/${jobId}/product/${product.productId}/${quality}`}
        >
          <img src={image['thumbnail']} alt="" className="object-contain rounded-md  pointer-events-none" />
        </Link>
        <span className="mt-2 flex items-center justify-center">
          {icon}
          <span className="ml-1 block truncate text-sm font-semibold">
            {product.customerProductId ?? product.productId}
          </span>
        </span>
      </li>
    );
  });

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <Link to={`/reports/${jobId}`} className="mr-8 flex-1 flex items-center ">
        <ChartBarSquareIcon className="mr-2 h-5 w-5 hover:text-indigo-900 text-indigo-400" aria-hidden="true" />
        <span className="text-gray-900 hover:underline">Generate Report</span>
      </Link>
    </div>
  );

  return (
    <PrivateTemplate
      headerActions={headerActions}
      headerTitle={`${headerTitle} › Products`}
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}
    >
      <Tabs nav={getProductNav(jobId, quality)} />

      <div className="bg-white flex flex-col flex-1 max-h-screen">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            {pageData.items.length > 0 ? (
              <div className="relative h-full">
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/products`} urlFilter={quality} />
                <section className=" sm:mb-8" aria-labelledby="gallery-heading">
                  <ul className="px-4 sm:px-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8">
                    {products}
                  </ul>
                </section>
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/products`} urlFilter={quality} />
              </div>
            ) : (
              <div className="border-t border-b border-gray-200">
                <EmptyState title="No Products" description="Please capture a few products" />
              </div>
            )}
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Products;
