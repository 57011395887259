import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Jobs from './private/Jobs';
import Job from './private/Job';
import AddJob from './private/AddJob';

import Reports from './private/Reports';

import Devices from './private/Devices';
import Device from './private/Device';
import AddDevice from './private/AddDevice';

import CompanyProfile from './private/CompanyProfile';

import Users from './private/Users';
import UpdateUser from './private/UpdateUser';
import InviteUser from './private/InviteUser';

import ChangePassword from './private/ChangePassword';

import Support from './private/Support';
import Dashboard from './private/Dashboard';
import Private404 from './private/Private404';

export const PrivateRoutes = () => {
  return (
    <Switch>
      <Redirect from="/signup" to="/" />
      <Redirect from="/confirm-signup" to="/" />
      <Redirect from="/resend-signup" to="/" />
      <Redirect from="/reset-password" to="/" />
      <Redirect from="/forgot-password" to="/" />

      <Redirect exact from="/job" to="/jobs" />
      <Redirect exact from="/job/:jobId/" to="/job/:jobId/products" />
      <Redirect exact from="/account" to="/account/company" />

      <Redirect exact from="/station" to="/stations" />
      <Redirect exact from="/station/:deviceId/" to="/station/:deviceId/settings" />

      <Redirect exact from="/user" to="/users" />

      <Route path="/jobs/add/:deviceId?" component={AddJob} />
      <Route path="/jobs/:pageNumber?/:filter?" component={Jobs} />
      <Route path="/job/:jobId/:section/:paramA?/:paramB?" component={Job} />

      <Route path="/reports/:jobId?" component={Reports} />

      <Route path="/stations/add" component={AddDevice} />
      <Route path="/stations/:pageNumber?/:filter?" component={Devices} />
      <Route path="/station/:deviceId/:section/:paramA?/:paramB?" component={Device} />

      <Route path="/account/company" component={CompanyProfile} />
      <Route path="/account/invite" component={InviteUser} />
      <Route path="/account/profile" component={UpdateUser} />
      <Route path="/account/change-password" component={ChangePassword} />
      <Route path="/account/users/:pageNumber?/:filter?" component={Users} />

      <Route exact path="/support" component={Support} />
      <Route exact path="/" component={Dashboard} />

      <Route component={Private404} />
    </Switch>
  );
};

export default PrivateRoutes;
