import { createSlice } from '@reduxjs/toolkit';

const whitelist = [
  'signUp',
  'signIn',
  'resendSignUp',
  'resetPassword',
  'forgotPassword',
  'changePassword',
  'confirmSignUp',
  'createJob',
  'deleteJob',
  'updateJob',
  'registerDevice',
  'updateDevice',
  'updateComponentState',
  'getDevices',
  'getJobs',
  'getCompany',
  'activateDeviceJob',
  'trainModel',
  'activateModel',
  'updateCompany',
  'updateUser',
  'getProductReport',
  'updateProduct',
  'inviteUser',
];

const messages = {
  resendSignUp: 'Check your email to confirm your account.',
  resetPassword: 'You can now login with your new password.',
  forgotPassword: 'If an account exists, a reset link will be sent to the email on file.',
  updateDevice: 'You have successfully updated your device.',
  updateComponentState: 'You have successfully updated your device.',
  signUp: 'Thanks for sigining up! Check your email to activate your account.',
  registerDevice: 'Congrats! You just activated a new system. Calibrate your device to get started.',
  deleteJob: 'Your job was successfully deleted.',
  updateJob: 'Your job was successfully updated.',
  updateProduct: 'Measurements were successfully updated.',
  changePassword: 'Your pssword was successfully updated. You can now login with your new password.',
  confirmSignUp: 'Welcome to Fomoro! Sign in with your new account.',
  activateDeviceJob: 'Your device is being updated.',
  updateCompany: 'Company information successfully updated.',
  updateUser: 'Your profile was successfully updated.',
  inviteUser: 'Successfully sent invite.',
};

const validate = action => {
  return whitelist.some(name => action.type.includes(name));
};

export const statusSlice = createSlice({
  name: 'status',
  initialState: {},
  reducers: {},
  extraReducers: builder =>
    builder.addMatcher(validate, (state, action) => {
      const [store, method, status] = action.type.split('/');
      switch (status) {
        case 'pending':
          state.message = undefined;
          break;
        case 'rejected':
          let errorMessage = action.error.message;
          if (method === 'inviteUser' || method === 'signUp') {
            errorMessage = errorMessage.replace('PreSignUp failed with error ', '');
          }
          state.message = errorMessage;
          break;
        case 'fulfilled':
          state.message = messages[method];
          break;
        default:
          break;
      }
      state.store = store;
      state.status = status;
      state.method = method;
      state.payload = action.payload;
      state.args = action.meta.arg;
    }),
});

export default statusSlice.reducer;
