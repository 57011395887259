import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

const FILTERS = ['GOOD', 'BAD', 'UNCERTAIN'];

export const getProducts = createAsyncThunk('products/getProducts', async payload => {
  const { jobId, query } = payload;
  if (!FILTERS.includes(query.quality)) delete query.quality;
  const queryStr = new URLSearchParams(query);
  try {
    return await API.get('api', `/jobs/${jobId}/products?${queryStr}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getProductReport = createAsyncThunk('products/getProductReport', async (payload, thunkAPI) => {
  const { jobId, start, stop } = payload;
  try {
    const queryStr = new URLSearchParams({ start, stop });
    return await API.get('api', `/jobs/${jobId}/products?${queryStr}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getProduct = createAsyncThunk('products/getProduct', async payload => {
  const { jobId, productId } = payload;
  try {
    return await API.get('api', `/jobs/${jobId}/products/${productId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateProduct = createAsyncThunk('products/updateProduct', async payload => {
  const { jobId, productId, labeledMeasurements } = payload;
  try {
    return await API.put('api', `/jobs/${jobId}/products/${productId}`, { body: { labeledMeasurements } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    items: {},
    next: {},
    reports: {},
  },
  reducers: {
    'updateProduct/pending': (state, action) => {
      const { jobId, productId, ...rest } = action.meta.arg;
      const product = state.items?.[jobId]?.[productId] || {};
      const updates = Object.entries(rest);

      updates.forEach(([key, value]) => {
        product[key] = value;
      });

      state.items[jobId][productId] = product;
    },
    'getProducts/fulfilled': (state, action) => {
      const { jobId, products, next } = action.payload;
      const jobProducts = state.items[jobId] ?? {};

      products.forEach(newProduct => {
        const { productId } = newProduct;
        const oldProduct = jobProducts[productId] ?? {};
        const updatedProduct = Object.assign(oldProduct, newProduct);
        jobProducts[productId] = updatedProduct;
      });
      state.items[jobId] = jobProducts;

      if (next) {
        state.next[jobId] = next;
      } else {
        delete state.next[jobId];
      }
    },
    'getProduct/fulfilled': (state, action) => {
      const product = action.payload;
      const { jobId, productId } = product;

      if (!(jobId in state.items)) {
        state.items[jobId] = {};
      }
      state.items[jobId][productId] = product;
    },
    'getProductReport/fulfilled': (state, action) => {
      const { jobId, products } = action.payload;
      const formattedReport = formatReportData(products);
      state.reports[jobId] = formattedReport;
    },
  },
});

const selectItems = (state, props) => state.products.items[props.jobId];
const selectItem = (state, props) => props.productId;
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectProduct = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectMeasurements = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, label, page) => {
    const items = Object.values(data ?? {});
    const labelFilter = label ? label.toUpperCase() : null;
    const filteredItems = FILTERS.includes(labelFilter)
      ? items.filter(item => item.labeledMeasurements && item.toleranceEvaluation === labelFilter)
      : items.filter(item => !item.labeledMeasurements);

    // pagination
    const total = filteredItems.length;
    const pageNumber = page ?? 1;
    const itemsPerPage = page ? 120 : total;
    const previousPage = pageNumber - 1;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
    };
  });
};

export const selectProducts = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, quality, page) => {
    const items = Object.values(data ?? {});
    const qualityFilter = quality ? quality.toUpperCase() : null;
    const filteredItems = FILTERS.includes(qualityFilter)
      ? items.filter(item => item.predictedQuality === qualityFilter)
      : items;

    // pagination
    const total = filteredItems.length;
    const pageNumber = page ?? 1;
    const itemsPerPage = page ? 120 : total;
    const previousPage = pageNumber - 1;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
    };
  });
};

const formatReportData = report => {
  const { data: reportData, headers: reportHeaders } = report.reduce(
    ({ data, headers }, product) => {
      const { images, predictedMeasurements, ...productData } = product;

      const date = new Date(parseInt(productData.productId, 10));
      productData.dateCaptured = date.toLocaleString();

      if (predictedMeasurements) {
        const { width, height, length } = predictedMeasurements;
        productData['width (mm)'] = width;
        productData['height (mm)'] = height;
        productData['length (mm)'] = length;
      }

      Object.entries(images).forEach(([camera, frame]) => {
        productData[`Image (Camera ${camera})`] = frame['original'];
      });

      delete productData.labeledMeasurements;
      data.push(productData);

      Object.keys(productData).forEach(key => {
        if (headers[key]) return;
        const label = key.charAt(0).toUpperCase() + key.slice(1);
        headers[key] = { key, label };
      });

      return { data, headers };
    },
    { data: [], headers: {} }
  );

  const headers = Object.values(reportHeaders);
  const data = reportData.length > 0 ? reportData : 'No products for these dates';

  return {
    headers,
    data,
  };
};

export default productsSlice.reducer;
