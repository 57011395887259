import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

export const getModels = createAsyncThunk('models/getModels', async (payload, thunkAPI) => {
  const { jobId } = payload;
  try {
    return await API.get('api', `/jobs/${jobId}/models`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getModel = createAsyncThunk('models/getModel', async (payload, thunkAPI) => {
  const { jobId, modelId } = payload;
  try {
    return await API.get('api', `/jobs/${jobId}/models/${modelId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const trainModel = createAsyncThunk('models/trainModel', async (payload, thunkAPI) => {
  const { jobId } = payload;
  try {
    return await API.post('api', `/jobs/${jobId}/models`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const activateModel = createAsyncThunk('models/activateModel', async (payload, thunkAPI) => {
  const { jobId, modelId } = payload;

  try {
    return await API.put('api', `/jobs/${jobId}/models/activate`, { body: { modelId } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const modelsSlice = createSlice({
  name: 'models',
  initialState: {
    items: {},
  },
  reducers: {
    'activateModel/fulfilled': (state, action) => {},
    'trainModel/fulfilled': (state, action) => {
      const model = action.payload;
      const { modelId, jobId } = model;

      if (!(jobId in state.items)) {
        state.items[jobId] = {};
      }
      state.items[jobId][modelId] = model;
    },
    'getModel/fulfilled': (state, action) => {
      const model = action.payload;
      const { modelId, jobId } = model;

      if (!(jobId in state.items)) {
        state.items[jobId] = {};
      }
      state.items[jobId][modelId] = model;
    },
    'getModels/fulfilled': (state, action) => {
      const { models } = action.payload;
      if (models.length === 0) return;

      const { jobId } = models[0];
      const jobModels = state.items[jobId] || {};

      models.forEach(model => {
        const { modelId } = model;
        jobModels[modelId] = model;
      });

      state.items[jobId] = jobModels;
    },
  },
  extraReducers: {},
});

const selectItems = (state, props) => state.models.items[props.jobId];
const selectItem = (state, props) => props.modelId;
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectTotal = (state, props) => state.frames.totals[props.jobId];

export const selectModel = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectModels = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, filter, page) => {
    const items = Object.values(data || {});
    const itemFilter = filter || 'all';
    const filteredItems = itemFilter === 'all' ? items : items.filter(item => item.status === filter);
    const sortedItems = filteredItems.sort((a, b) => a.createdAt - b.createdAt);

    // pagination
    const pageNumber = page || 1;
    const itemsPerPage = 120;
    const previousPage = pageNumber - 1;
    const total = sortedItems.length;
    const pages = Math.ceil(total / itemsPerPage);

    const results = sortedItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
    };
  });
};

export default modelsSlice.reducer;
