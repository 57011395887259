import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

export const registerDevice = createAsyncThunk('devices/registerDevice', async (payload, thunkAPI) => {
  const { deviceId, deviceName } = payload;
  try {
    const device = await API.post('api', '/devices', { body: { deviceId, deviceName } });
    const components = await API.get('api', `/components`);
    await API.put('api', `/devices/${deviceId}/components`, {
      body: { QualityControl: { version: components.QualityControl.latestVersion } },
    });
    return device;
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getDevice = createAsyncThunk('devices/getDevice', async (payload, thunkAPI) => {
  const { deviceId } = payload;
  try {
    return await API.get('api', `/devices/${deviceId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getDevices = createAsyncThunk('devices/getDevices', async (payload, thunkAPI) => {
  // TODO: Add pagination
  try {
    return await API.get('api', '/devices');
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateDevice = createAsyncThunk('devices/updateDevice', async (payload, thunkAPI) => {
  const { deviceId, deviceName, cameraSettings } = payload;
  try {
    return await API.put('api', `/devices/${deviceId}`, { body: { deviceName, cameraSettings } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateComponent = createAsyncThunk('devices/updateComponent', async (payload, thunkAPI) => {
  const { deviceId, componentName } = payload;
  try {
    const components = await API.get('api', `/components`);
    const version = components[componentName]['latestVersion'];
    return await API.put('api', `/devices/${deviceId}/components/${componentName}`, {
      body: { version },
    });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getDeviceJobs = createAsyncThunk('devices/getDeviceJobs', async (payload, thunkAPI) => {
  const { deviceId } = payload;
  try {
    return await API.get('api', `/devices/${deviceId}/jobs`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const activateDeviceJob = createAsyncThunk('devices/activateDeviceJob', async (payload, thunkAPI) => {
  const { deviceId, jobId, mode } = payload;
  try {
    return await API.put('api', `/devices/${deviceId}/jobs/activate`, { body: { jobId, mode } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getDeviceCalibration = createAsyncThunk('devices/getDeviceCalibration', async (payload, thunkAPI) => {
  const { deviceId } = payload;
  try {
    return await API.get('api', `/devices/${deviceId}/calibration`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateComponentState = createAsyncThunk('devices/updateComponentState', async (payload, thunkAPI) => {
  const { deviceId, componentName, state } = payload;
  try {
    return await API.put('api', `/devices/${deviceId}/state/${componentName}`, { body: state });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    jobs: {},
  },
  reducers: {
    'getDevice/fulfilled': (state, action) => {
      const { deviceId, ...updates } = action.payload;
      const device = state.items[deviceId] ?? { deviceId };
      const updatedDevice = Object.assign({}, device, updates);
      state.items[deviceId] = updatedDevice;
    },
    'getDevices/fulfilled': (state, action) => {
      const { devices } = action.payload;
      const oldItems = state.items;
      const newItems = devices.reduce((newItems, newDevice) => {
        const oldDevice = oldItems[newDevice.deviceId] || {};
        newItems[newDevice.deviceId] = {
          ...newDevice,
          ...oldDevice,
        };
        return newItems;
      }, {});

      state.items = newItems;
    },
    'updateDevice/fulfilled': (state, action) => {
      const { deviceId, ...updates } = action.meta.arg;
      const device = state.items[deviceId] ?? { deviceId };
      const updatedDevice = Object.assign({}, device, updates);
      state.items[deviceId] = updatedDevice;
    },
    'getDeviceJobs/fulfilled': (state, action) => {
      const { deviceId, jobs } = action.payload;
      state.jobs[deviceId] = jobs;
    },
    'activateDeviceJob/fulfilled': (state, action) => {
      const { deviceId, jobId, mode } = action.meta.arg;
      const item = state.items[deviceId] || { deviceId };
      item.activeJobId = jobId;

      const deviceState = Object.assign({ desired: { mode } }, item.state);
      deviceState.desired.mode = mode;
      item.state = deviceState;

      state.items[deviceId] = item;
    },
    'getDeviceCalibration/fulfilled': (state, action) => {
      const { deviceId, ...updates } = action.payload;
      const device = state.items[deviceId] ?? { deviceId };
      const updatedDevice = Object.assign({}, device, updates);
      state.items[deviceId] = updatedDevice;
    },
  },
  extraReducers: builder =>
    builder.addMatcher(
      action => action.type === 'jobs/createJob/fulfilled',
      (state, action) => {
        const { deviceId } = action.meta.arg;

        const componentState = {
          deviceId,
          componentName: 'QualityControl',
          state: {
            mode: 'SETUP',
          },
        };
        updateComponentState(componentState);
      }
    ),
});

const selectItems = (state, props) => state.devices.items;
const selectItem = (state, props) => state.devices.items[props.deviceId];
const selectJobs = (state, props) => state.devices.jobs[props.deviceId];
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectDeviceJobs = () => {
  return createSelector([selectJobs], jobs => {
    return jobs || [];
  });
};

export const selectAllDevices = () => {
  return createSelector([selectItems], items => {
    return Object.values(items).map(device => {
      return { name: device.deviceName, id: device.deviceId, status: true };
    });
  });
};

export const selectComponentState = () => {
  return createSelector([selectItem, selectFilter], (item, componentName) => {
    const state = item?.state || {};
    const component = state[componentName] || {};
    const reported = component.state?.reported || {};
    const desired = component.state?.desired || {};
    return { desired, reported };
  });
};

export const selectDevice = () => {
  return createSelector([selectItem], data => {
    return data || {};
  });
};

export const selectDevices = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, filter, page) => {
    const items = Object.values(data || {});
    const search = filter?.toLowerCase();
    const filteredItems = filter
      ? items.filter(item => {
          const success =
            item.deviceId.toLowerCase().startsWith(search) ||
            item.deviceName.toLowerCase().startsWith(search) ||
            item.activeJobId.toLowerCase().startsWith(search) ||
            item.activeJobName.toLowerCase().startsWith(search);
          return success;
        })
      : items;

    // pagination
    const pageNumber = page || 1;
    const itemsPerPage = 24;
    const previousPage = pageNumber - 1;
    const total = filteredItems.length;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
      filter,
    };
  });
};

export default devicesSlice.reducer;
