import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export const inviteUser = createAsyncThunk('users/inviteUser', async (payload, thunkAPI) => {
  const { email, password, givenName, familyName } = payload;

  await Auth.signUp({
    username: email,
    password: password,
    attributes: {
      email: email,
      given_name: givenName,
      family_name: familyName,
    },
  });

  try {
    await API.post('api', '/users/invite', {
      body: { email },
    });
    return { givenName, familyName, email };
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getUser = createAsyncThunk('users/getUser', async (payload, thunkAPI) => {
  const { userId } = payload;
  try {
    return await API.get('api', `/users/${userId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getUsers = createAsyncThunk('users/getUsers', async (payload, thunkAPI) => {
  // TODO: Add pagination
  try {
    return await API.get('api', '/users');
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async (payload, thunkAPI) => {
  const { userId, title, givenName, familyName, phone } = payload;

  const body = { givenName, familyName };
  if (title) {
    body.title = title;
  }
  if (phone) {
    body.phone = phone;
  }

  try {
    return await API.put('api', `/users/${userId}`, { body });
  } catch (err) {
    throw thunkAPI.rejectWithValue(err.response);
  }
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    items: {},
  },
  reducers: {
    'getUser/fulfilled': (state, action) => {
      const { payload } = action;
      state.items[payload.userId] = payload;
    },
    'getUsers/fulfilled': (state, action) => {
      const { users } = action.payload;
      const oldItems = state.items;
      const newItems = users.reduce((newItems, newUser) => {
        const oldUser = oldItems[newUser.userId] || {};
        newItems[newUser.userId] = {
          ...newUser,
          ...oldUser,
        };
        return newItems;
      }, {});

      state.items = newItems;
    },
    'updateUser/fulfilled': (state, action) => {
      const { userId } = action.payload;
      const item = state.items[userId] || { userId };
      const updates = Object.entries(action.payload);
      updates.forEach(([key, value]) => {
        item[key] = value;
      });
      state.items[userId] = item;
    },
  },
});

const selectItems = (state, props) => state.users.items;
const selectItem = (state, props) => state.users.items[props.userId];
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectUser = () => {
  return createSelector([selectItem], data => {
    return data || {};
  });
};

export const selectUsers = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, filter, page) => {
    const items = Object.values(data || {});
    const search = filter?.toLowerCase();
    const filteredItems = filter
      ? items.filter(item => {
          const success =
            item.givenName.toLowerCase().startsWith(search) || item.familyName.toLowerCase().startsWith(search);
          return success;
        })
      : items;

    // pagination
    const pageNumber = page || 1;
    const itemsPerPage = 24;
    const previousPage = pageNumber - 1;
    const total = filteredItems.length;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
      filter,
    };
  });
};

export default usersSlice.reducer;
