import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { updateJob } from 'state/slices/jobsSlice';
import { activateDeviceJob } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';
import Tabs from '../components/Tabs';

import getJobSettingsNav from '../navs/jobSettingsNav';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

export const JobSettings = props => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { headerTitle, sectionNav, job, sectionTitle } = props;
  const { message, status, method } = useSelector(state => state.status);

  const { activeJobId, state, deviceId } = job.device;
  const [isActiveJob] = useState(activeJobId === jobId);

  const formik = useFormik({
    initialValues: {
      jobName: job.jobName,
    },
    validate: values => {
      const errors = {};
      if (!values.jobName) {
        errors.jobName = 'Required';
      }
      return errors;
    },
    onSubmit: values => {
      const { jobName } = values;
      const jobId = job.jobId;
      dispatch(updateJob({ jobId, jobName }));
    },
  });

  const changeMode = mode => {
    dispatch(
      activateDeviceJob({
        deviceId,
        jobId,
        mode,
      })
    );
  };

  return (
    <PrivateTemplate sectionNav={sectionNav} sectionTitle={sectionTitle} headerTitle={`${headerTitle} › Settings`}>
      <Tabs nav={getJobSettingsNav(jobId, 'settings', job)} />
      {method === 'updateJob' && status === 'rejected' && <WarningBanner text={message} />}
      {method === 'updateJob' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${job.jobName}`} />
      )}
      <div className="max-w-7xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <div className="max-w-2xl">
            <div role="group" aria-labelledby="label-notifications">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div
                    className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                    id="label-notifications">
                    Job Status
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="max-w-lg">
                    <div className="mt-2 space-y-4">
                      <div className="flex items-center">
                        <input
                          onClick={() => changeMode('READY')}
                          id="job-ready"
                          defaultChecked={isActiveJob && state?.desired?.mode === 'READY'}
                          name="job-status"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="job-ready" className="ml-3 block text-sm font-medium text-gray-700">
                          Ready
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          onClick={() => changeMode('ACTIVE')}
                          defaultChecked={isActiveJob && state?.desired?.mode === 'ACTIVE'}
                          id="job-running"
                          name="job-status"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="job-running" className="ml-3 block text-sm font-medium text-gray-700">
                          Running
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-b border-gray-200 max-w-7xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <form onSubmit={formik.handleSubmit} className="max-w-2xl mt-6">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                <label htmlFor="jobId" className="block text-sm font-medium text-gray-900">
                  Job Id
                </label>
                <input
                  type="text"
                  readOnly
                  name="jobId"
                  id="jobId"
                  value={job.jobId}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="deviceName" className="block text-sm font-medium text-gray-900">
                  Station Name
                </label>
                <input
                  type="text"
                  readOnly
                  name="deviceName"
                  id="deviceName"
                  value={job.device.deviceName}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="deviceId" className="block text-sm font-medium text-gray-900">
                  Station Id
                </label>
                <input
                  type="text"
                  readOnly
                  name="deviceId"
                  id="deviceId"
                  value={job.device.deviceId}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                {formik.errors.jobName && formik.touched.jobName && <Error>{formik.errors.jobName}</Error>}
                <label htmlFor="jobName" className="block text-sm font-medium text-gray-900">
                  Job Name
                </label>
                <input
                  type="text"
                  name="jobName"
                  id="jobName"
                  onChange={formik.handleChange}
                  value={formik.values.jobName}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-gray-900 sm:text-sm focus:ring-indigo-700 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className="pt-8 flex justify-end">
              <button
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default JobSettings;
