import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { getProductReport } from 'state/slices/productsSlice';
import { getJobs, selectAllJobs } from 'state/slices/jobsSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import StatusDropDown from '../components/StatusDropDown';
import WarningBanner from '../components/WarningBanner';
import DownloadBanner from '../components/DownloadBanner';
import EmptyState from '../components/EmptyState';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};

const validate = values => {
  const errors = {};
  if (!values.startDate) {
    errors.startDate = 'Required';
  }
  if (!values.stopDate) {
    errors.stopDate = 'Required';
  }
  return errors;
};

export const Reports = props => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { message, status, args, method } = useSelector(state => state.status);
  const reports = useSelector(state => state.products.reports);

  const getSelectedJobs = useMemo(selectAllJobs, []);
  const jobs = useSelector(state => getSelectedJobs(state));
  const [selectedId, setSelectedId] = useState(jobId);
  const job = useSelector(state => state.jobs.items?.[selectedId]) ?? {};

  useEffect(() => {
    dispatch(getJobs());
  }, []); // eslint-disable-line

  const formik = useFormik({
    initialValues: {
      startDate: '',
      stopDate: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const firstJobId = jobs[0]['id'];
      const jobId = selectedId ?? firstJobId;
      const start = new Date(values.startDate + 'T00:00').getTime();
      const stop = new Date(values.stopDate + 'T00:00').getTime();
      dispatch(
        getProductReport({
          start,
          stop,
          jobId,
        })
      );
      setSelectedId(jobId);
    },
  });

  const handleJobChange = job => {
    setSelectedId(job.id);
  };

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <PrivateTemplate headerTitle="Reports">
      {method === 'getProductReport' && status === 'fulfilled' && (
        <DownloadBanner
          text={`Successfully generated a report for ${job.jobName}`}
          headers={reports[args.jobId].headers}
          filename={`${job.jobName}-${formik.values.startDate}-${formik.values.stopDate}.csv`}
          data={reports[args.jobId].data}
        />
      )}
      {method === 'getProductReport' && status === 'rejected' && <WarningBanner text={message} />}

      {!jobs || jobs.length === 0 ? (
        <EmptyState
          title="No Jobs"
          description="All reports are attached to a specific job. You must have at least one registered job to create a report."
          cta="Add Job"
          to="/jobs/add/"
        />
      ) : (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label htmlFor="job" className="block text-sm font-medium text-gray-700">
                      Job
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.jobId && formik.touched.jobId && <Error>{formik.errors.jobId}</Error>}
                      <StatusDropDown selectedId={selectedId} changeHandler={handleJobChange} items={jobs} />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Start Date
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.startDate && formik.touched.startDate && <Error>{formik.errors.startDate}</Error>}
                      <input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.startDate}
                        className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="stopDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      End Date
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.stopDate && formik.touched.stopDate && <Error>{formik.errors.stopDate}</Error>}
                      <input
                        id="stopDate"
                        name="stopDate"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.stopDate}
                        className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Link
                  to="/jobs"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={status === 'pending'}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                >
                  Generate
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default Reports;
