import React, { useState } from 'react';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';

import { XCircleIcon } from '@heroicons/react/24/solid';
import { CSVLink } from 'react-csv';

export const DownloadBanner = props => {
  const [showBanner, setShowBanner] = useState(true);
  if (!showBanner) return null;

  const { data, headers, mobile, text, buttonText = 'Download', filename = 'Report' } = props;

  return (
    <div className="bg-green-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-green-800">
              <HandThumbUpIcon className="h-6 w-6 text-white" />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">{mobile ? mobile : text}</span>
              <span className="hidden md:inline">{text}</span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <CSVLink
              filename={filename}
              headers={headers}
              data={data}
              target="_blank"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50"
            >
              {buttonText}
            </CSVLink>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={() => setShowBanner(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadBanner;
