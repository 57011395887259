import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { getCompany, updateCompany } from 'state/slices/companySlice';
import getAccountNav from '../navs/accountNav';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};

const validate = values => {
  const errors = {};
  if (!values.companyName) {
    errors.companyName = 'Required';
  }
  if (!values.addressOne) {
    errors.addressOne = 'Required';
  }
  if (!values.city) {
    errors.city = 'Required';
  }
  if (!values.state) {
    errors.state = 'Required';
  }
  if (!values.zip) {
    errors.zip = 'Required';
  }

  return errors;
};

export const CompanyProfile = () => {
  const dispatch = useDispatch();
  const { company } = useSelector(state => state.company);
  const sectionNav = getAccountNav('company');
  const { message, method, status } = useSelector(state => state.status);

  useEffect(() => {
    dispatch(getCompany());
  }, []); // eslint-disable-line

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: company.companyName,
      addressOne: company.addressOne,
      addressTwo: company.addressTwo,
      city: company.city,
      state: company.state,
      zip: company.zip,
      website: company.website,
      industry: company.industry,
    },
    validate,
    onSubmit: values => {
      dispatch(updateCompany(values));
    },
  });

  const handleSubmit = e => {
    formik.handleSubmit();
    e.preventDefault();
  };

  return (
    <PrivateTemplate headerTitle="Account › Company" sectionTitle="Manage Account" sectionNav={sectionNav}>
      {method === 'updateCompany' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${formik.values.companyName}`} />
      )}
      {method === 'updateCompany' && status === 'rejected' && <WarningBanner text={message} />}
      <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="space-y-6 sm:space-y-4">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                  <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Company Name*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.companyName && formik.touched.companyName && (
                      <Error>{formik.errors.companyName}</Error>
                    )}
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                      autoComplete="organization"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="address_1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Address Line 1*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.addressOne && formik.touched.addressOne && <Error>{formik.errors.addressOne}</Error>}
                    <input
                      type="text"
                      name="addressOne"
                      id="addressOne"
                      autoComplete="address-line1"
                      onChange={formik.handleChange}
                      value={formik.values.addressOne}
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="addressTwo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Address Line 2
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.addressTwo && formik.touched.addressTwo && <Error>{formik.errors.addressTwo}</Error>}
                    <input
                      type="text"
                      name="addressTwo"
                      id="addressTwo"
                      autoComplete="address-line2"
                      onChange={formik.handleChange}
                      value={formik.values.addressTwo}
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    City*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.city && formik.touched.city && <Error>{formik.errors.city}</Error>}
                    <input
                      type="text"
                      name="city"
                      id="city"
                      onChange={formik.handleChange}
                      autoComplete="address-level2"
                      value={formik.values.city}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    State*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.state && formik.touched.state && <Error>{formik.errors.state}</Error>}
                    <input
                      type="text"
                      name="state"
                      id="state"
                      autoComplete="address-level1"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="zip" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    ZIP Code*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.zip && formik.touched.zip && <Error>{formik.errors.zip}</Error>}
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      autoComplete="postal-code"
                      onChange={formik.handleChange}
                      value={formik.values.zip}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="website" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Website
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.website && formik.touched.website && <Error>{formik.errors.website}</Error>}
                    <input
                      type="url"
                      name="website"
                      id="website"
                      autoComplete="url"
                      onChange={formik.handleChange}
                      value={formik.values.website}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="industry" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Industry
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.industry && formik.touched.industry && <Error>{formik.errors.industry}</Error>}
                    <input
                      type="text"
                      name="industry"
                      id="industry"
                      onChange={formik.handleChange}
                      value={formik.values.industry}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </PrivateTemplate>
  );
};

export default CompanyProfile;
