import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';

import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { getDeviceJobs, selectDeviceJobs } from 'state/slices/devicesSlice';
import { activateDeviceJob } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import WarningBanner from '../components/WarningBanner';

import classNames from 'utils/classNames';

export const DeviceJobs = props => {
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);

  const { deviceId } = useParams();
  const { device, headerTitle, headerActions, sectionNav } = props;

  const getSelectedDeviceJobs = useMemo(selectDeviceJobs, []);
  const jobs = useSelector(state => getSelectedDeviceJobs(state, { deviceId, filter: 'jobs' }));

  useEffect(() => {
    dispatch(getDeviceJobs({ deviceId }));
  }, [deviceId]); // eslint-disable-line

  const activateJobHandler = jobId => {
    setShowBanner(device.activeJobId === jobId);
    dispatch(activateDeviceJob({ deviceId, jobId, mode: 'ACTIVE' }));
  };
  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Jobs`}
      headerActions={headerActions}
      sectionNav={sectionNav}
      sectionTitle="Manage Station"
    >
      {showBanner && <WarningBanner text="Job already active. Please activate another job." />}

      {!jobs || jobs.length === 0 ? (
        <EmptyState
          title="No Jobs"
          description="Get started by creating a new job."
          cta="Add Job"
          to={`/jobs/add/${deviceId}`}
        />
      ) : (
        <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Job
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {jobs.map(job => (
                    <tr key={job.jobId}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <Switch.Group as="div" className="flex items-center justify-between">
                          <Switch
                            checked={device?.activeJobId === job.jobId}
                            onChange={() => activateJobHandler(job.jobId)}
                            className={classNames(
                              device?.activeJobId === job.jobId ? 'bg-indigo-800' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                device?.activeJobId === job.jobId ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Link to={`/job/${job.jobId}/labels`}>
                            <div className="flex-shrink-0 h-8 w-8">
                              {device?.activeJobId === job.jobId ? (
                                <ClipboardDocumentCheckIcon className="h-6 w-6 text-gray-400" />
                              ) : (
                                <ClipboardDocumentIcon className="h-6 w-6 text-gray-400" />
                              )}
                            </div>
                          </Link>
                          <Link to={`/job/${job.jobId}`}>
                            <div className="ml-2 lg:ml-6">
                              <div className="text-sm font-medium ">{job.jobName}</div>
                              <div className="text-sm text-gray-500">{job.jobId}</div>
                            </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default DeviceJobs;
